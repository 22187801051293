// order matters on these imports
// see: https://github.com/amazon-connect/amazon-connect-chatjs#a-note-about-the-aws-sdk-and-chatjs
import "amazon-connect-streams";
import "amazon-connect-chatjs";
import "aws-sdk/clients/connectparticipant";

import type { ChatTranscript } from "../../chat-transcripts";
import {
  ChatTranscriptImpl,
  NewChatTranscriptImplOptions,
  SetInitialMessagesSucceededState,
  SetSessionEndedState,
} from "./chat-transcript-impl";
import { ChatTranscriptPersistenceManager } from "./chat-transcript-persistence-manager";

/**
 * Manager capable of:
 * - capturing chat transcripts of contacts in near real-time
 * - obtaining captured chat transcripts
 * - persisting and loading captured chat transcript
 */
export class ChatTranscriptManager {
  private readonly persistence: ChatTranscriptPersistenceManager;
  private readonly transcripts: Record<string, ChatTranscriptImpl>;

  constructor() {
    this.persistence = new ChatTranscriptPersistenceManager();
    this.transcripts = this.persistence.get();
  }

  /**
   * Gets the chat transcript of a contact.
   * @param contactId The id of the contact to obtain the transcript
   * @returns The chat transcript if found, `null` otherwise
   */
  public getTranscript(contactId: string): ChatTranscript | null {
    const transcript = this.transcripts[contactId];
    return transcript?.finished ? transcript.toJSON() : null;
  }

  /**
   * Gets the realtime chat transcript of a contact.
   * @param contactId The id of the contact to obtain the transcript
   * @returns The chat transcript if found, `null` otherwise
   */
  public getRealTimeChatTranscripts(contactId: string): ChatTranscript | null {
    const transcript = this.transcripts[contactId];
    return transcript ? transcript.toJSON() : null;
  }

  /** Enables the capturing of chat transcript in near real-time. */
  public startChatTranscriptCapture(): void {
    connect.contact((c) => {
      if (c.getType() === connect.ContactType.CHAT) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        c.onAccepted(() =>
          this.captureContactChatTranscript(c).catch((err) =>
            console.error(
              `Failed to capture transcript for contact: ${c.getContactId()}`,
              err
            )
          )
        );
      }
    });
  }

  private static async getChatSession(
    contact: connect.Contact
  ): Promise<connect.AgentChatSession> {
    const agentCnn = contact
      .getConnections()
      .find((c) => c.getType() === connect.ConnectionType.AGENT);

    if (!agentCnn) {
      throw new Error(
        `No agent connection found for contact: ${contact.getContactId()}`
      );
    }

    if (!(agentCnn instanceof connect.ChatConnection)) {
      throw new Error(
        `No agent chat connection found for contact: ${contact.getContactId()}`
      );
    }

    const session: connect.AgentChatSession = (await agentCnn.getMediaController()) as connect.AgentChatSession;
    if (!session) {
      throw new Error(
        `No chat session found for contact: ${contact
          .getContactId()
          .toString()}`
      );
    }

    return session;
  }

  private getOrCreateTranscript(
    options: NewChatTranscriptImplOptions
  ): ChatTranscriptImpl {
    const { contactId } = options;
    let transcript = this.transcripts[contactId];
    if (!transcript) {
      transcript = new ChatTranscriptImpl(options);
      this.transcripts[contactId] = transcript;
    }

    return transcript;
  }

  private handleEnded(event: connect.ChatEndedEvent): void {
    this.setTranscriptState(event.chatDetails.contactId, {
      sessionEnded: true,
    });
  }

  private handleMessage(event: connect.ChatMessageEvent): void {
    const { contactId, initialContactId } = event.chatDetails;
    const transcript = this.updateTranscript({
      contactId,
      initialContactId,
      messages: [event.data],
    });

    /*
     * `amazon-connect-chatjs` has a bug where the `.onEnded()` event does not trigger
     * after a succesful contact transfer. As a result, some chat transcripts may never
     * finish or be persisted. To prevent this, we have to manually detect successful
     * contact transfers and eensure the transcripts are finished.
     */
    if (transcript.transferred) {
      this.handleEnded(event);
    }
  }

  private async loadInitialMessages(
    session: connect.AgentChatSession
  ): Promise<void> {
    const { contactId, initialContactId } = session.getChatDetails();
    const messages: connect.ChatTranscriptItem[] = [];
    let data: connect.GetTranscriptResult | null = null;
    let initialMessagesSucceeded = true;
    try {
      // get and paginate through the entire chat transcript
      do {
        const res: connect.ParticipantServiceResponse<connect.GetTranscriptResult> = await session.getTranscript(
          {
            // 100 is the max allowed
            maxResults: 100,
            nextToken: data?.NextToken,
            // messages are retrieved newest to oldest, so we sort the pages descending
            sortOrder: "DESCENDING",
          }
        );

        if (res.data) {
          data = res.data;
          messages.push(...data.Transcript);
        }
      } while (data?.NextToken);

      // sort records in chronological order
      messages.reverse();
    } catch (err) {
      console.error(
        `Failed to load initial messages for contact: ${contactId}`,
        err
      );

      // remove partial messages
      messages.splice(0, messages.length);
      initialMessagesSucceeded = false;
    }

    // update transcript and indicate that the initial messages have been loaded
    this.updateTranscript({
      contactId,
      initialContactId,
      messages,
    });
    this.setTranscriptState(contactId, { initialMessagesSucceeded });
  }

  private setTranscriptState(
    contactId: string,
    state: SetInitialMessagesSucceededState
  ): void;
  private setTranscriptState(
    contactId: string,
    state: SetSessionEndedState
  ): void;
  private setTranscriptState(
    contactId: string,
    state: SetInitialMessagesSucceededState | SetSessionEndedState
  ): void {
    const transcript = this.transcripts[contactId];
    if (
      transcript &&
      !transcript.finished &&
      // eslint-disable-next-line @typescript-eslint/ban-types
      (transcript.setState as Function)(state)
    ) {
      if (transcript.initialMessagesSucceeded) {
        const evicted = this.persistence.persist(transcript);
        if (evicted) {
          // cleanup evicted transcripts
          delete this.transcripts[evicted.ContactId];
        }
      } else {
        // do not persist transcripts whose initial messages failed
        delete this.transcripts[contactId];
      }
    }
  }

  private async captureContactChatTranscript(
    contact: connect.Contact
  ): Promise<void> {
    const session = await ChatTranscriptManager.getChatSession(contact);

    /*
     * We subscribe to events and load the the initial messages (i.e. messages not captured by `onMessage()`).
     * The sequence of events is non-deterministic (i.e. order cannot be determined at compile time).
     * Hence we rely on `ChatTranscriptImpl`'s:
     * - `.addMessages()`'s deduping/sorting
     * - `.setState()`'s ability to determine sequence of events
     */
    session.onEnded((event) => this.handleEnded(event));
    session.onMessage((event) => this.handleMessage(event));
    await this.loadInitialMessages(session);
  }

  private updateTranscript(
    options: NewChatTranscriptImplOptions
  ): ChatTranscriptImpl {
    const transcript = this.getOrCreateTranscript(options);
    if (!transcript.finished) {
      transcript.addMessages(...options.messages);
    }

    return transcript;
  }
}
