import "./style.scss";

import { Button, SpaceBetween } from "@amzn/awsui-components-react-v3";
import React, { useContext, useEffect, useRef, useState } from "react";

import { logger } from "../../logger";
import { AppStateContext } from "../../state/provider";
import { assistSurveyLink } from "../config";
import { useMounted } from "../hooks/common";

export const AssistSurveyPanel: React.FC<unknown> = () => {
  const refCurrentViewedContactId = useRef<string>(); // This is use to keep track of the current contact on the screen.
  const mounted = useMounted();
  const appState = useContext(AppStateContext);
  const [displaySurvey, setDisplaySurvey] = useState<boolean>(false);

  /**
   * This function returns true if the specified contact is an ASSIST consulatation and has ended
   * @param contact the Amazon Connect contact object
   * @returns
   */
  const isEndedAssistChatContact = (contact: connect.Contact): boolean => {
    // determine if it is an ended assist contact
    return (
      contact?.getAttributes()["sourceApp"]?.value === "ASSIST" &&
      contact.getState().type === connect.ContactStateType.ENDED &&
      contact.getType() === connect.ContactType.CHAT
    );
  };

  /**
   * This function returns the contact object of the specified contact id corresponding to an agent
   * @param agent the agent object.
   * @param contactId the contactId in Amazon Connect.
   * @returns
   */
  const findChatContact = (
    agent: connect.Agent,
    contactId: string
  ): connect.Contact | undefined => {
    const contact = agent
      .getContacts(connect.ContactType.CHAT)
      .find((contact) => contact.contactId === contactId);
    return contact;
  };

  useEffect(() => {
    if (appState.ccpInitialized) {
      connect.agent((agent) => addConnectEventListeners(agent));
    }
  }, [appState.ccpInitialized]);

  // add connect event listeners
  const addConnectEventListeners = (agent: connect.Agent): void => {
    // get contact id of current contact in view
    // subscribe to changes in the viewed contact and
    // display the survey panel if the contact in view is an assist contact and has ended
    connect.core.onViewContact((viewedContact) => {
      if (mounted.current) {
        refCurrentViewedContactId.current = viewedContact.contactId;
        const contact = findChatContact(agent, viewedContact.contactId);
        if (contact) {
          setDisplaySurvey(isEndedAssistChatContact(contact));
        } else {
          // This is not always an error, e.g if the viewed contact is for a different channel than chat
          logger.error(
            `Contact: ${viewedContact.contactId} was not found amongst the agent list`
          );
          setDisplaySurvey(false);
        }
      }
    });
    connect.contact((contact) => {
      // display the survey panel for assist contact in view that has ended
      contact.onEnded((contact) => {
        if (mounted.current) {
          if (refCurrentViewedContactId.current === contact.contactId) {
            setDisplaySurvey(isEndedAssistChatContact(contact));
          }
        }
      });
    });
  };
  return displaySurvey ? (
    <div className="survey-container">
      <SpaceBetween size="m">
        <div className="title-container">We need your feedback</div>
        <SpaceBetween direction="vertical" size="s">
          <div className="message-container">
            Your feedback will improve consultations.
          </div>
          <Button
            ariaLabel="Survey button"
            href={assistSurveyLink()}
            iconAlign="right"
            iconName="external"
            target="_blank"
            className="awsui-visual-refresh"
          >
            Tell us your thoughts
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </div>
  ) : (
    <></>
  );
};
