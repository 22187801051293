import "amazon-connect-streams";

import { ChatTranscript } from "../chat-transcripts";
import { getCcpWindowUrl } from "../configuration";
import { AmazonConnectInstance } from "../profiles-reader";
import { ChatTranscriptManager } from "./chat-transcript-manager";

/** The error codes that the CCP manager can present. */
export type ErrorCode =
  | "ALREADY_INITIALIZED"
  | "INIT_FAILED"
  | "INIT_IN_PROGRESS"
  | "AUTH_FAILED"
  | "ACCESS_DENIED"
  | "NOT_INITIALIZED"
  | "WINDOW_OPEN_FAILED";

/** Represents and error of the CCP lifecycle manager. */
export class CcpManagerError extends Error {
  constructor(
    public readonly message: ErrorCode,
    public readonly cause?: Error
  ) {
    super(message);
  }
}

/** Messge sent via `window.postMessage()`, used to communicate between CCP window and base window. */
export interface WindowMessage {
  /** Type of message. */
  readonly type: "SELECTED_INSTANCE_CHANGE";
  /** Currently selected instance. */
  readonly selectedInstance: AmazonConnectInstance;
}

/** The `CcpManager` initialization options. */
export interface InitOptions {
  /** The `HTMLElement` where the CCP will be loaded into. */
  container: HTMLElement;

  /** The Amazon Connect instance that the CCP will come from. */
  instance: AmazonConnectInstance;

  /** A callback that will be executed when the CCP popup is closed. */
  onWindowClose: () => void;

  /** A callback that will be executed when a message is received. */
  onMessageReceive: (message: WindowMessage) => void;
}

/** Manages the lifecycle of the CCP. */
export class CcpManager {
  private readonly chatTranscriptManager: ChatTranscriptManager;

  private initOptions?: InitOptions;
  private initPromise?: Promise<void>;
  private ccpWindow?: {
    window: Window;
    intervalHandle: number;
  };

  // we can only have one instance of `CcpManger`
  // because `amazon-connect-streams` does not support multiple CCPs.
  public static readonly instance: CcpManager = new CcpManager();

  // @VisibleForTesting
  protected constructor() {
    this.chatTranscriptManager = new ChatTranscriptManager();
  }

  /** Indicates whether the CCP is currently initialized. */
  public get initialized(): boolean {
    return !!this.initOptions;
  }

  public get initOps(): InitOptions | undefined {
    return this.initOptions;
  }

  public get poppedCcpWindow():
    | { window: Window; intervalHandle: number }
    | undefined {
    return this.ccpWindow;
  }

  public setPoppedCcpWindow(windowToSet: {
    window: Window;
    intervalHandle: number;
  }): void {
    this.ccpWindow = windowToSet;
  }

  /**
   * Gets the chat transcript of a contact.
   * @param contactId The id of the contact to obtain the transcript
   * @returns The chat transcript if found, `null` otherwise
   */
  public getChatTranscript(contactId: string): ChatTranscript | null {
    return this.chatTranscriptManager.getTranscript(contactId);
  }

  /**
   * Gets the realtime chat transcript of a contact.
   * @param contactId The id of the contact to obtain the transcript
   * @returns The chat transcript if found, `null` otherwise
   */
  public getRealTimeChatTranscripts(contactId: string): ChatTranscript | null {
    return this.chatTranscriptManager.getRealTimeChatTranscripts(contactId);
  }

  /**
   * Initializes the CCP.
   * @param options The init options
   */
  public init(options: InitOptions): Promise<void> {
    if (this.initPromise) {
      return Promise.reject(new CcpManagerError("INIT_IN_PROGRESS"));
    }

    if (this.initOptions) {
      return Promise.reject(new CcpManagerError("ALREADY_INITIALIZED"));
    }

    this.initPromise = new Promise<void>((resolve, reject) => {
      const { container, instance } = options;
      try {
        // init CCP
        connect.core.initCCP(container, {
          ...instance,
          loginPopup: true,
          loginOptions: {
            autoClose: true,
            width: 800,
            height: 800,
          },
          softphone: {
            allowFramedSoftphone: true,
          },
          pageOptions: {
            enableAudioDeviceSettings: true,
            enablePhoneTypeSettings: true,
          },
          /**
           * As part of Google's Privacy Sandbox initiative, Google Chrome has announced plans to block third-party cookies
           * (that is, cookies passed between two top level domains).
           * Setting the mode to 'custom' tries to hide or show the ContainerDiv based on the cookie permissions
           * https://docs.aws.amazon.com/connect/latest/adminguide/admin-3pcookies.html
           **/
          storageAccess: {
            mode: "custom",
          },
        });

        // handle init CCP errors
        const handleCcpError = (error: CcpManagerError): void => {
          this.execTerminate(container);
          reject(error);
        };
        connect.core.onAuthFail(() =>
          handleCcpError(new CcpManagerError("AUTH_FAILED"))
        );
        connect.core.onAccessDenied(() =>
          handleCcpError(new CcpManagerError("ACCESS_DENIED"))
        );

        // wait for CCP init to finish
        connect.agent(() => {
          this.initOptions = options;
          this.chatTranscriptManager.startChatTranscriptCapture();
          resolve();
        });
      } catch (err) {
        this.execTerminate(container);
        reject(new CcpManagerError("INIT_FAILED", err as Error));
      }
    }).finally(() => (this.initPromise = undefined));

    return this.initPromise;
  }

  /**
   * Opens a popup window to the CCP.
   * If the popup window is already open, the popup is focused instead.
   */
  public openWindow(): void {
    if (!this.initOptions) {
      throw new CcpManagerError("NOT_INITIALIZED");
    }

    // ccp window is still open, focus it
    if (this.ccpWindow) {
      const { window } = this.ccpWindow;
      if (!window.closed) {
        window.focus();
        return;
      }
    }

    // no ccp window, or previous ccp window is closed, create a new one
    const ccpWindow = window.open(
      getCcpWindowUrl() + "?instance=" + this.initOptions.instance.ccpUrl,
      "agent.js CCP",
      // CCP width/height: 200px to 320px | 400px to 465px + status bar
      // see: https://github.com/amazon-connect/amazon-connect-streams/blob/master/Documentation.md#a-few-things-to-note-1
      "height=500,width=330,menubar=0,status=0,toolbar=0"
    );

    if (!ccpWindow) {
      throw new CcpManagerError("WINDOW_OPEN_FAILED");
    }

    const { onWindowClose, onMessageReceive } = this.initOptions;

    // Before main window is closed, close the CCP popup window if it exists.
    // eslint-disable-next-line @typescript-eslint/unbound-method
    window.addEventListener("unload", this.closeWindow);

    window.addEventListener("message", (event) => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        event.data?.type?.toString() ??
        ("" as string) === "SELECTED_INSTANCE_CHANGE"
      ) {
        console.debug(
          "[ITSupportConnectAgentClient] Received a message event: ",
          event
        );
        console.debug(
          "[ITSupportConnectAgentClient] CCP window URL: ",
          getCcpWindowUrl()
        );
      }
      // Only listen to messages coming from CCP popup window.
      if (event.origin === getCcpWindowUrl()) {
        onMessageReceive(event.data as WindowMessage);
      }
    });

    // detect when window closes and call onWindowClose handler
    const intervalHandle = setInterval(() => {
      if (ccpWindow.closed) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.removeEventListener("unload", this.closeWindow);
        clearInterval(intervalHandle);
        onWindowClose();
      }
    }, 500);

    this.ccpWindow = {
      window: ccpWindow,
      // casting required due to `@types/node`.
      intervalHandle: (intervalHandle as unknown) as number,
    };
  }

  /** Terminates the CCP and closes the popup window (if any). */
  public terminate(): void {
    if (!this.initOptions) {
      throw new CcpManagerError("NOT_INITIALIZED");
    }

    this.execTerminate(this.initOptions.container);
  }

  /** Closes the open popped out ccp window*/
  public closeWindow(): void {
    if (CcpManager.instance.ccpWindow) {
      CcpManager.instance.ccpWindow.window.close();
    }
  }

  private execTerminate(container: HTMLElement): void {
    // terminate the CCP
    connect.core.terminate();

    // cleanup the container (i.e. <iframe>)
    for (const child of container.children) {
      child.remove();
    }

    this.initOptions = undefined;
  }
}
