import "./style.scss";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  INCOMING_MSG_AUDIO_NOTIFICATION,
  notifyAgentAboutInactiveChat,
} from "../../../audio-notification";
import { CcpContainer } from "../../CcpContainer";
import {
  useCloseCcpWindow,
  useInitSelectedInstanceCcp,
  useOpenCcpWindow,
} from "../../hooks/ccp-manager";
import { useMounted } from "../../hooks/common";
import { useInstanceSelector } from "../../hooks/instance-selector";
import { LaunchMenu } from "../../LaunchMenu";

export interface MainContainer {
  onCloseClick?: () => void;
  connectInstanceCcpUrl?: string;
}

/**
 * The root component that has access to the redux store.
 * Controls the Ccp and Launch menu
 */
export const MainContainer: React.FC<MainContainer> = ({
  onCloseClick,
  connectInstanceCcpUrl,
}) => {
  // Ref to the ccpContainer instance methods
  const ccpContainerRef = useRef<HTMLDivElement>(null);

  const { setSelectedInstance, instances } = useInstanceSelector();

  // Local State
  const [isLaunched, setIsLaunched] = useState<boolean>(false);

  // CCP callbacks
  const initSelectedInstanceCcp = useInitSelectedInstanceCcp();

  const initCcp = useCallback(async () => {
    if (ccpContainerRef.current) {
      await initSelectedInstanceCcp(ccpContainerRef.current);
    }
  }, [ccpContainerRef, initSelectedInstanceCcp]);

  // Custom Hooks
  const openCcpWindow = useOpenCcpWindow();
  const closeCcpWindow = useCloseCcpWindow();
  const mounted = useMounted();

  // Handlers
  // for popping out agent.html and back in
  const handlePopClick = (): void => {
    if (mounted.current) {
      // if connectInstanceCcpUrl is present it means it is already agent.html
      connectInstanceCcpUrl ? closeCcpWindow() : openCcpWindow();
    }
  };

  const handleLaunchClick = async (): Promise<void> => {
    setIsLaunched(true);
    await initCcp();

    connect.contact(
      (contact: {
        onConnected: (arg0: (connectedContact: any) => Promise<void>) => void;
      }) => {
        contact.onConnected(async (connectedContact) => {
          const agentConnection = (connectedContact as connect.Contact).getAgentConnection();
          if (agentConnection instanceof connect.ChatConnection) {
            const chatSession = (await agentConnection.getMediaController()) as connect.ChatSession;
            chatSession.onMessage((event) => {
              notifyAgentAboutInactiveChat(event);
            });
          }
        });
      }
    );
  };

  useEffect(() => {
    if (connectInstanceCcpUrl) {
      const selectedInstance = instances.find(
        (instance) => instance.ccpUrl === connectInstanceCcpUrl
      );
      setSelectedInstance(selectedInstance);
      void handleLaunchClick();
    }
  }, []);

  const handleCloseClick = (): void => {
    onCloseClick && onCloseClick();
  };

  return (
    <Fragment>
      {!connectInstanceCcpUrl && (
        <LaunchMenu
          onLaunchClick={handleLaunchClick}
          onCloseClick={handleCloseClick}
        />
      )}
      <CcpContainer
        style={{ visibility: isLaunched ? "visible" : "hidden" }}
        ref={ccpContainerRef}
        onPopClick={handlePopClick}
        onCloseClick={handleCloseClick}
      />
    </Fragment>
  );
};
