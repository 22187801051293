import { CognitoOidcSsoLoginCredentials } from "@amzn/cognito-oidc-sso-login-client";

import { Stage } from "../../configuration";
import { CONNECT_BETA_URL, CONNECT_DEV_URL, CONNECT_PROD_URL } from "../config";

/** Gets the stage from the connect website URL. */
export function getStage(): Stage {
  switch (window.location.hostname) {
    case CONNECT_DEV_URL:
      return "dev";
    case CONNECT_PROD_URL:
      return "prod";
    case CONNECT_BETA_URL:
    default:
      return "beta";
  }
}

/** The authentication base URL to get AWS creds. */
export function getCognitoOidcBaseUrl(stage?: Stage): string {
  switch (stage || getStage()) {
    case "dev":
    case "beta":
      return "https://beta.omnia.it.a2z.com";
    case "gamma":
      return "https://gamma.omnia.it.a2z.com";
    case "prod":
      return "https://omnia.it.a2z.com";
  }
}

/** The dialog base URL. */
export function getDialogReturnUrl(stage?: Stage): URL {
  return new URL(`${getCognitoOidcBaseUrl(stage)}/cognito-oidc-sso-login.html`);
}

const CREDENTIALS = new CognitoOidcSsoLoginCredentials(
  new URL(getCognitoOidcBaseUrl()),
  getDialogReturnUrl()
);

/** Does a fetch to webappURL/sso/login to get AWS creds */
export async function auth(): Promise<CognitoOidcSsoLoginCredentials> {
  await CREDENTIALS.getPromise();
  return CREDENTIALS;
}
